import React from 'react';
import FaqItem from '@common/FaqItem';
import ExternalLink from '@common/ExternalLink';
import styled from 'styled-components';

import { Section, Container } from '@components/global';
import { BsClockFill, BsShieldLockFill, BsSquareFill, BsTagFill } from "react-icons/bs";

const Grid = styled.section`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 1.4rem;
  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

const GridItem = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  
  p {
    margin-top: 1rem;
  }
  @media screen and (max-width: 900px) {
    align-items: flex-start;
    padding: 0;
    margin-bottom: 1.5rem;
    p {
      margin-top: 0.5rem;
    }
  }
`;




const MFaq = () => (
  <Section id="faq">
    <Container>
      <h1 style={{ marginBottom: 40 }}>Minilager</h1>

      <Grid>
        <GridItem>
          <BsClockFill size={35} style={{ color: '#C72c41' }} />

          <p>Åpent 06-23 hele året</p>
        </GridItem>
        <GridItem>
          <BsShieldLockFill size={35} style={{ color: '#C72c41' }} />
          <p>Personlig kode og overvåket</p>
        </GridItem>
        <GridItem>
          <BsSquareFill size={35} style={{ color: '#C72c41' }} />
          <p>Lager på ca. 11m2 og ca. 20m2</p>
        </GridItem>
        <GridItem>
          <BsTagFill size={35} style={{ color: '#C72c41' }} />

          <p>Kun 750,- per måned for ca. 11m2</p>
          <p>Kun 1400,- per måned for ca. 20m2</p>
        </GridItem>
      </Grid>

      <div>
        <p>
          Vi tilbyr store boder i helt nyoppussete lokaler! Vårt minilager
          ligger sentralt til i Indre Østfold med kort avstand til Rakkestad,
          Mysen og Askim. Lageret ligger lett tilgjengelig med kun 150 m fra
          riksvei 22. Minilageret har brann- og innbruddsalarm, og er
          kameraovervåket. I tillegg loggføres samtlige besøk ved minilageret
          for å sikre trygg lagring og god oversikt.
        </p>
        <br />
        <p>
          Vårt minilager er lyst og oppvarmet, og har i tillegg installert
          avfukter som sikrer godt inneklima. Lageret har gjennomgående god
          plass som sikrer enkel fremkommelighet både til fots og med tralle.
          Alle dører er terskelfrie slik at lastede paller kan trilles helt inn
          til lagerplass. Utvendig er det rikelig med plass til bil og henger.
          Parkeringsplassen er stor, og det enkelt å rygge inntil rampe og
          lagerport. I lageret har du tilgang på bagasjetralle og jekketralle.
        </p>
        <br />
        <p>
          Som leietaker lager du din personlige kode slik at du lettvint har
          tilgang på boden din når det måtte passe deg innenfor åpningstidene.
          Lagerets åpningstider er kl. 06:00-23:00, hver dag hele året.
        </p>
        <br />

        <p>
          Våre lagerboder har en størrelse på ca 11m2 eller ca. 20m2. Fast
          månedspris per bod på ca. 11m2 er kr 750,-. Fast månedspris per bod på
          ca. 20m2 er kr 1400,-
        </p>
        <br />
        <p>
          Har du behov for mer eller mindre plass kan vi tilpasse dette, ta
          kontakt så finner vi en lagerløsning som passer deg!
        </p>
      </div>
    </Container>
  </Section>
);



export default MFaq;
