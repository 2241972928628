import React from 'react';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';
import MHeader from '@sections/MHeader';
import About from '@sections/About';
import MFaq from '@sections/MFaq';
import Footer from '@sections/Footer';
import Team from '@sections/Team';
import MInfo from '@sections/MInfo';
import Map from '@sections/Map';

const CampingVogn = () => (
  <Layout>
    <Navbar />
    <MHeader />
    <MInfo />
    <MFaq />
    <Team />
    <Map />
    <Footer />
  </Layout>
);

export default CampingVogn;
